import { Banner, Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { LoginScreen } from '@motion/ui/login'
import { templateStr } from '@motion/ui-logic'
import { isEmailValid } from '@motion/utils/string'

import { useState } from 'react'

import { TermsPrivacyConsent } from '../components/terms-privacy-consent'

interface MobileSignUpPageProps {
  title: string
  handleSignInEmail: (email: string) => void
}

export const MobileSignUpPage = ({
  title,
  handleSignInEmail,
}: MobileSignUpPageProps) => {
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const onSignupClick = () => {
    if (isEmailValid(email)) {
      setEmailInvalid(false)
      setShowConfirmation(true)
    } else {
      setEmailInvalid(true)
    }
  }

  const renderLoginButtons = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <TextField
            placeholder='Enter email'
            type='email'
            value={email}
            onChange={(value) => setEmail(value.toLowerCase())}
          />
          {emailInvalid && (
            <Banner variant='onPage' sentiment='error'>
              Invalid email. Please try again
            </Banner>
          )}
          <Button
            // @ts-expect-error - old styles
            className='checkout-survey-submit-button'
            fullWidth
            style={{ height: 48 }}
            onClick={onSignupClick}
          >
            Sign up
          </Button>
          <TermsPrivacyConsent />
        </div>
      </>
    )
  }

  return (
    <>
      <LoginScreen
        iframeUrl=''
        title={title}
        subtitle='Motion is guaranteed to help you get more deep work done. Cancel
      anytime in 1 click.'
      >
        {renderLoginButtons()}
      </LoginScreen>
      {showConfirmation && (
        <div className='checkout-survey-background'>
          <div className='checkout-survey-container flex flex-col items-start px-6 py-16 bg-white gap-6'>
            <h3 className='font-bold text-3xl'>Welcome to Motion!</h3>

            <p className='text-lg'>
              {templateStr(
                'Please confirm the email {{email}} is correct (no typos).',
                {
                  email: <span className='font-bold'>{email}</span>,
                }
              )}
            </p>
            <div className='flex-col flex w-full gap-3'>
              <Button
                onClick={() => handleSignInEmail(email)}
                fullWidth
                size='normal'
              >
                Confirm
              </Button>
              <Button
                onClick={() => setShowConfirmation(false)}
                sentiment='neutral'
                variant='outlined'
                fullWidth
              >
                Change email
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
