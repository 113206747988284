import { getSegmentAnalytics, logEvent } from '@motion/web-common/analytics'
import { isMobileExperience } from '@motion/web-common/env'
import { Sentry } from '@motion/web-common/sentry'

import { loadStripe } from '@stripe/stripe-js'
import { getTimeZones } from '@vvo/tzdb'
import {
  type Auth,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { IANAZone } from 'luxon'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import {
  createUserWithFirebaseEmailPassword,
  signInWithFirebaseEmailPassword,
  signInWithFirebasePopup,
  signInWithFirebaseRedirect,
} from './areas/auth'
import { getEnv, isDev } from './env'
import {
  serverSideStripeSubscriptionCheck,
  type SubscriptionData,
} from './serverSideSubscription'

export const allTimezones = getTimeZones().map((tz) => {
  const momentAbbr = IANAZone.create(tz.name).offsetName(Date.now(), {
    format: 'short',
  })
  if (!/\d/.test(momentAbbr)) {
    tz.abbreviation = momentAbbr
    return { ...tz, abbreviation: momentAbbr }
  }
  return { ...tz }
})

export const getTzAbbr = (timezoneName: string) => {
  const searchName =
    timezoneName === 'Asia/Calcutta' ? 'Asia/Kolkata' : timezoneName
  const zone = allTimezones.find(
    (tz) => tz.name === searchName || tz.group.includes(searchName)
  )
  if (zone) {
    return zone.abbreviation
  }
  const momentAbbr = IANAZone.create(timezoneName).offsetName(Date.now(), {
    format: 'short',
  })
  return /\d/.test(momentAbbr)
    ? momentAbbr
        .replace('+0', '+')
        .replace('-0', '-')
        .replace('+', 'GMT+')
        .replace('-', 'GMT-')
    : momentAbbr
}

export const sendResetPasswordEmail = async (
  auth: Auth,
  email: string
): Promise<{
  outcome: boolean
  error?: { code: string; message: string }
}> => {
  try {
    await sendPasswordResetEmail(auth, email)
    return { outcome: true }
  } catch (e) {
    Sentry.captureException(e, { tags: { position: 'sendResetPasswordEmail' } })
    return { outcome: false, error: e as any }
  }
}

export const logSignInEvent = (providerId: string) => {
  switch (providerId) {
    case 'google.com':
      logEvent('DASHBOARD_GOOGLE_SIGNIN')
      getSegmentAnalytics()?.track('WEBAPP_SIGNIN', {
        provider: 'google',
      })
      break
    case 'microsoft.com':
      logEvent('DASHBOARD_MS_SIGNIN')
      getSegmentAnalytics()?.track('WEBAPP_SIGNIN', {
        provider: 'microsoft',
      })
      break
    case 'apple.com':
      logEvent('DASHBOARD_APPLE_SIGNIN')
      getSegmentAnalytics()?.track('WEBAPP_SIGNIN', { provider: 'apple' })
      break
    default:
      break
  }
}

export const signInGoogle = async (auth: Auth, redirect?: boolean) => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account',
  })
  try {
    logEvent('DASHBOARD_GOOGLE_SIGNIN_CLICK')
    const result = redirect
      ? await signInWithFirebaseRedirect(auth, provider)
      : await signInWithFirebasePopup(auth, provider)
    logSignInEvent('google.com')
    if (result.state === 'VALID') {
      return result.credential
    }
    return
  } catch (e) {
    Sentry.captureException(e, { tags: { position: 'signIn' } })
    return { error: e }
  }
}

export const signInMicrosoft = async (auth: Auth, redirect?: boolean) => {
  const provider = new OAuthProvider('microsoft.com')
  provider.setCustomParameters({
    prompt: 'select_account',
  })
  try {
    logEvent('DASHBOARD_MS_SIGNIN_CLICK')
    const result = redirect
      ? await signInWithFirebaseRedirect(auth, provider)
      : await signInWithFirebasePopup(auth, provider)
    logSignInEvent('microsoft.com')
    if (result.state === 'VALID') {
      return result.credential
    }
    return
  } catch (e) {
    Sentry.captureException(e, { tags: { position: 'signInMicrosoft' } })
    return { error: e }
  }
}

export const signInApple = async (auth: Auth, redirect?: boolean) => {
  const provider = new OAuthProvider('apple.com')
  provider.setCustomParameters({
    prompt: 'select_account',
  })
  try {
    logEvent('DASHBOARD_APPLE_SIGNIN_CLICK')
    const result = redirect
      ? await signInWithFirebaseRedirect(auth, provider)
      : await signInWithFirebasePopup(auth, provider)
    logSignInEvent('apple.com')
    if (result.state === 'VALID') {
      return result.credential
    }
    return
  } catch (e) {
    Sentry.captureException(e, { tags: { position: 'signInApple' } })
    return { error: e }
  }
}

export const signUpEmail = async (
  auth: Auth,
  email: string,
  password: string
) => {
  const response = await createUserWithFirebaseEmailPassword(
    auth,
    email,
    password
  )

  if (response.state === 'VALID') {
    logEvent('DASHBOARD_MOTION_SIGNUP', { email })
    getSegmentAnalytics()?.track('WEBAPP_SIGNIN', { provider: 'password' })
    return { outcome: true }
  }

  // Error
  logEvent('DASHBOARD_MOTION_SIGNIN_FAILURE', { email })
  return { outcome: false, error: response.error }
}

export const signInEmail = async (
  auth: Auth,
  email: string,
  password: string
) => {
  const response = await signInWithFirebaseEmailPassword(auth, email, password)

  if (response.state === 'VALID') {
    logEvent('DASHBOARD_MOTION_SIGNIN', { email })
    getSegmentAnalytics()?.track('WEBAPP_SIGNIN', { provider: 'password' })
    return { outcome: true }
  }

  // Error
  logEvent('DASHBOARD_MOTION_SIGNIN_FAILURE', { email })
  return { outcome: false, error: response.error }
}

export const signOut = async (auth: Auth) => {
  await auth.signOut()
}

export const checkStripeSubscription = async (
  auth: Auth
): Promise<SubscriptionData | null> => {
  const currentUser = auth.currentUser
  if (!currentUser) {
    throw new Error('no user')
  }
  const sub = await serverSideStripeSubscriptionCheck(auth)
  if (!sub) {
    return null
  }
  return sub
}

const DEV_CLOUDFUNCTIONS_BASE =
  'https://us-central1-motion-dev-366719.cloudfunctions.net/'
const PROD_CLOUDFUNCTIONS_BASE =
  'https://us-central1-light-relic-254202.cloudfunctions.net/'

export const getApiUrl = (path: string) => {
  const env = getEnv()
  const base =
    env === 'development' || env === 'localhost'
      ? DEV_CLOUDFUNCTIONS_BASE
      : PROD_CLOUDFUNCTIONS_BASE
  const suffix = env === 'development' || env === 'localhost' ? 'Dev' : ''
  return `${base}${path}${suffix}`
}

export const getCloudRunUrl = (path: string) => {
  const base = import.meta.env.MOTION_BACKEND_HOST
  return `${base}/${path}`
}

export const getBookingUrl = (cloudRunUrl: string, param?: string) => {
  return param
    ? `${getCloudRunUrl(cloudRunUrl)}/${param}`
    : getCloudRunUrl(cloudRunUrl)
}

export const useOnPurchaseRedirect = () => {
  const navigate = useNavigate()
  return useCallback(() => {
    if (isMobileExperience()) {
      window.location.href = 'https://www.usemotion.com/checkout-success-mobile'
    } else {
      navigate('/web')
    }
  }, [navigate])
}

export const generatePaymentIntent = async ({
  url,
  emailOverride,
  idToken,
}: {
  url: string
  emailOverride?: string
  idToken?: string
}) => {
  const headers: {
    'Content-Type': string
    Authorization?: string
  } = {
    'Content-Type': 'application/json',
  }
  if (idToken) {
    headers['Authorization'] = 'Bearer ' + idToken
  }
  const body: { email?: string } = {}
  if (emailOverride) {
    body.email = emailOverride
  }
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
    if (resp.ok) {
      const response = await resp.json()
      if (response.error) {
        throw response.error
      }
      return response.client_secret
    }
    throw new Error('Something went wrong, please contact support')
  } catch (e) {
    logEvent('GENERATE_PAYMENT_INTENT_FAIL')
    Sentry.captureException(e, {
      tags: { position: 'generatePaymentIntent' },
    })
  }
  return null
}

let stripePromise: any
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      isDev
        ? 'pk_test_ZDQQFkW9TeMexOR6rF2weB5Q00k51CIQZm'
        : 'pk_live_bXkyvmoKFB9KZsMI4NNJH36E00bksv6Yuq'
    )
  }
  return stripePromise
}
