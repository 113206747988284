import { AlertModal } from '@motion/ui/base'

import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { BookingPage } from './booking/booking-page'

// Hardcode the valid URL prefixes so that users can't create fake enterprise pages
const VALID_URL_PREFIXES = ['antonio-garcia', 'maddy']

export const EnterprisePage = () => {
  const { urlPrefix = '' } = useParams<{
    urlPrefix: string
  }>()

  const [showEnterpriseContinueModal, setShowEnterpriseContinueModal] =
    useState(false)

  if (!VALID_URL_PREFIXES.includes(urlPrefix)) {
    window.location.href = 'https://www.usemotion.com'
    return
  }

  return (
    <div className='flex flex-col items-center font-semibold gap-4 pt-12 px-12'>
      <div className='flex flex-col gap-2 items-center'>
        <h1 className='text-2xl'>Book a demo call with us</h1>
        <h2 className='text-lg text-semantic-neutral-text-subtle'>
          Learn why Motion is the best software tailored to your specific
          project management needs
        </h2>
      </div>
      <div className='w-[1200px] rounded-xl overflow-clip'>
        <BookingPage
          onBookingCreated={() => setShowEnterpriseContinueModal(true)}
        />
      </div>

      <EnterpriseContinueModal visible={showEnterpriseContinueModal} />
    </div>
  )
}

type EnterpriseContinueModalProps = {
  visible: boolean
}

const EnterpriseContinueModal = ({ visible }: EnterpriseContinueModalProps) => {
  const navigate = useNavigate()

  return (
    <AlertModal
      visible={visible}
      onClose={() => {}}
      title='Try Motion for free'
      actions={[
        {
          label: 'Continue',
          onAction: () => {
            navigate('/checkout')
          },
          sentiment: 'primary',
        },
      ]}
    >
      <p className='text-semantic-neutral-text-subtle'>
        If you'd like, you are encouraged to try out our product for free ahead
        of our call.
      </p>
    </AlertModal>
  )
}
