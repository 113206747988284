import {
  CogSolid,
  CurrencyDollarSolid,
  QuestionMarkCircleSolid,
  UserSolid,
} from '@motion/icons'
import {
  Banner,
  Button,
  ConfirmationModal,
  LoadingSpinner,
} from '@motion/ui/base'
import {
  INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT,
  INDIVIDUAL_ANNUAL_PRICE,
  INDIVIDUAL_MONTHLY_PRICE,
} from '@motion/ui-logic/billing'
import { errorInDev } from '@motion/web-common/logging'
import {
  newOnboardingPrePurchasePages,
  usePersistedOnboardingState,
} from '@motion/web-common/onboarding'
import { Sentry } from '@motion/web-common/sentry'
import { EnsureFirestoreSettingsFetched } from '@motion/web-common/settings'

import { useShouldShowLoginEmailNotFound } from '~/areas/auth/hooks'
import { LoadingPage } from '~/areas/global'
import logo from '~/images/logo_1024.png'
import { getApp } from 'firebase/app'
import {
  type Auth,
  getAuth,
  onAuthStateChanged,
  type User,
} from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { logEvent } from './analytics'
import { LoginEmailNotFoundModal } from './areas/auth/modals/login-email-not-found-modal'
import { LoginPage } from './areas/auth/pages/login-page'
import { DesktopRedirect } from './DesktopRedirect'
import { type SubscriptionData } from './serverSideSubscription'
import { checkStripeSubscription, getCloudRunUrl, signOut } from './utils'

const STRIPE_PORTAL_URL = getCloudRunUrl('users/stripePortalLink')
const UPGRADE_PLAN_URL = getCloudRunUrl('upgradeStripePlanAnnual')

export const AccountPage = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User | null>(null)
  const [subscription, setSubscription] = useState<SubscriptionData | null>(
    null
  )
  const [customer, setCustomer] = useState(null)
  const auth = getAuth(getApp())

  const shouldShowLoginEmailNotFound = useShouldShowLoginEmailNotFound()
  const [loginNoEmailFound, setLoginNoEmailFound] = useState<string | null>(
    null
  )
  const navigate = useNavigate()

  const params = new URLSearchParams(window.location.search)
  const isDesktop = params.has('desktop')
  const isWebPopup = params.has('webapp')
  const hasSource = params.has('source')

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // We want to show the modal if the user is not coming from onboarding
        if (!hasSource && (await shouldShowLoginEmailNotFound(user))) {
          setLoginNoEmailFound(user.email)
        } else if (isWebPopup) {
          window.close()
          return
        } else {
          setUser(user)
        }
      } else {
        setUser(null)
      }
      setLoading(false)
    })
  }, [
    auth,
    auth.currentUser,
    isWebPopup,
    shouldShowLoginEmailNotFound,
    hasSource,
  ])

  useEffect(() => {
    const checkSubscription = async () => {
      const subscriptionData = await checkStripeSubscription(auth)
      setSubscription(subscriptionData)
      setCustomer(subscriptionData?.customer)
    }
    if (user) {
      checkSubscription()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className='fill' data-theme='light'>
      {loading && <LoadingPage id='webapp-account' />}
      {!loading && (
        <>
          {!user && (
            <>
              <LoginPage showIframe={false} />
              {!!loginNoEmailFound && (
                <LoginEmailNotFoundModal
                  email={loginNoEmailFound}
                  onSignUp={() => {
                    if (isWebPopup) {
                      window.close()
                      return
                    }
                    setLoading(true)
                    navigate('/web')
                  }}
                  onDismiss={() => {
                    setLoginNoEmailFound(null)
                    setUser(null)
                    auth.signOut()
                    window.close()
                  }}
                />
              )}
            </>
          )}
          {user && (
            <>
              {isDesktop && <DesktopRedirect user={user} />}
              {!isDesktop && !loginNoEmailFound && !isWebPopup && (
                <AccountBody
                  auth={auth}
                  user={user}
                  subscription={subscription}
                  customer={customer}
                  subscriptionHandler={setSubscription}
                />
              )}
              {!isDesktop && !!loginNoEmailFound && (
                <LoginEmailNotFoundModal
                  email={loginNoEmailFound}
                  onSignUp={() => {
                    if (isWebPopup) {
                      window.close()
                      return
                    }
                    setLoading(true)
                    navigate('/web')
                  }}
                  onDismiss={() => {
                    setLoginNoEmailFound(null)
                    setUser(null)
                    auth.signOut()
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

const AccountBody = ({
  auth,
  user,
  subscription,
  customer,
  subscriptionHandler,
}: {
  auth: Auth
  user: User
  subscription: SubscriptionData | null
  customer: any
  subscriptionHandler: (subscription: any) => void
}) => {
  const [pageIdx, setPageIdx] = useState(0)
  const [hash, setHash] = useState(window.location.hash)

  useEffect(() => {
    const handleLocationChange = () => {
      setHash(window.location.hash)
    }

    window.addEventListener('hashchange', handleLocationChange)
    window.addEventListener('popstate', handleLocationChange)

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('hashchange', handleLocationChange)
      window.removeEventListener('popstate', handleLocationChange)
    }
  }, [])

  useEffect(() => {
    if (hash === '#settings') {
      setPageIdx(0)
    } else if (hash === '#subscription') {
      setPageIdx(1)
    } else if (hash === '#support') {
      setPageIdx(2)
    }
  }, [hash])

  const renderMenu = () => {
    const subscriptionInterval =
      subscription?.subscription?.plan?.interval ?? 'none'
    const hasSubscription = !!subscription?.subscription?.plan?.interval
    const isTeamSubscription =
      subscription?.type === 'team_plan' ||
      subscription?.type === 'team_plan_trial'

    return (
      <div className='account-menu flex flex-col items-stretch pr-3'>
        <div
          className='account-menu-header'
          onClick={() => (window.location.href = 'https://www.usemotion.com')}
        >
          <img style={{ margin: '8px' }} width={32} height={32} src={logo} />
          <h2
            style={{
              marginBottom: 0,
              fontWeight: 'bold',
              fontSize: 30,
              lineHeight: 1.35,
            }}
          >
            motion
          </h2>
        </div>
        <hr className='my-5' />
        <p className='mb-2'>MY ACCOUNT</p>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={() => {
            window.location.hash = '#settings'
            setHash('#settings')
          }}
        >
          <CogSolid className='account-menu-icon w-4 h-4 text-semantic-neutral-icon-default' />
          <span className='account-menu-item-text mr-auto'>
            Account Settings
          </span>
        </Button>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={() => {
            window.location.hash = '#subscription'
            setHash('#subscription')
          }}
        >
          <CurrencyDollarSolid className='account-menu-icon w-4 h-4 text-semantic-neutral-icon-default' />
          <p className='account-menu-item-text mr-auto'>
            Billing{' '}
            {hasSubscription &&
              subscriptionInterval !== 'year' &&
              !isTeamSubscription && (
                <span
                  style={{
                    color: '#2c77e7',
                    marginBottom: 0,
                    marginLeft: 5,
                  }}
                >
                  SAVE {INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT}%
                </span>
              )}
          </p>
        </Button>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={() => {
            window.location.hash = '#support'
            setHash('#support')
          }}
        >
          <QuestionMarkCircleSolid className='account-menu-icon w-4 h-4 text-semantic-neutral-icon-default' />
          <span className='account-menu-item-text mr-auto'>Support</span>
        </Button>
        <hr className='my-5' />
        <p className='mb-2'>SIGNED IN AS</p>
        <div className='account-menu-item ml-3'>
          <UserSolid className='account-menu-icon w-4 h-4 text-semantic-neutral-icon-default' />
          <span className='account-menu-item-text'>
            {user?.displayName ? user?.displayName : user.email}
          </span>
        </div>
        {user?.displayName && (
          <span className='ml-[22px] pl-3'>{user.email}</span>
        )}
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={() => {
            logEvent('EXTERNAL_ACCOUNT_LOG_OUT')
            signOut(auth)
          }}
        >
          <span className='account-menu-item-text mr-auto'>Log Out</span>
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className='account-menu-bar'>{renderMenu()}</div>
      {pageIdx === 0 && <AccountSettingsPage auth={auth} user={user} />}
      {pageIdx === 1 && (
        <SubscriptionPage
          auth={auth}
          subscription={subscription}
          subscriptionHandler={subscriptionHandler}
          customer={customer}
        />
      )}
      {pageIdx === 2 && <SupportPage />}
    </>
  )
}

const AccountSettingsPage = ({ auth, user }: { auth: Auth; user: User }) => {
  const [, setDetachLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [modalVisible, setModalVisible] = useState(false)

  const detachCalendars = async () => {
    setDetachLoading(true)
    try {
      await fetch(getCloudRunUrl('detach_calendars'), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
        },
      })
      setMessage(
        'Your calendar is being detached. This process may take a few minutes. If there are still tasks on your calendar several minutes after running this job, please try re-running it.'
      )
    } catch (e) {
      setMessage('There was an error detaching your calendars.')
    }
    setDetachLoading(false)
    setModalVisible(false)
    logEvent('ACCOUNT_DETACH_CALENDARS')
  }

  return (
    <div className='fill settings-main'>
      <div className='settings-card flex flow-col gap-2'>
        <div>
          <h3 className='account-menu-title'>My Account</h3>
          <hr className='title-divider' />
        </div>
        <div>
          <p className='font-bold'>Name</p>
          <p>{user.displayName}</p>
        </div>
        <p />
        <div>
          <p className='font-bold'>Email</p>
          <p>{user.email}</p>
        </div>
        <p />
        <div>
          <p className='font-bold'>Disconnect Motion</p>
          <p>Deletes all your tasks and Motion events. Cannot be undone.</p>
        </div>
        <Button
          sentiment='error'
          // @ts-expect-error - old styling
          className='account-button'
          onClick={() => setModalVisible(true)}
        >
          Delete Motion Tasks
        </Button>
        <p>{message}</p>

        <ConfirmationModal
          title='Detach Calendars'
          description='Are you sure you want to detach your calendars? Your flexible events and synced events will be deleted.'
          visible={modalVisible}
          destructive
          action={{ label: 'Detach', onAction: detachCalendars }}
          closeLabel='Cancel'
          onClose={() => setModalVisible(false)}
        />

        <div className='flex flex-col [&>a]:text-blue-400'>
          <p className='font-bold'>Links</p>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.usemotion.com/privacy'
          >
            Privacy Policy
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.usemotion.com/terms'
          >
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  )
}

export const UpgradePanel = ({
  subscription,
  subscriptionHandler,
  visibleHandler,
  getPortalLink,
  portalLoading,
}: {
  subscription: any
  subscriptionHandler: (sub: any) => void
  visibleHandler: (visible: boolean) => void
  getPortalLink: (event: any) => any
  portalLoading: boolean
}) => {
  const [upgradeSuccess, setUpgradeSuccess] = useState(false)
  const [upgradeFail, setUpgradeFail] = useState(false)
  const [upgradeLoading, setUpgradeLoading] = useState(false)
  const auth = getAuth(getApp())

  const annualPlanHandler = useCallback(async () => {
    try {
      setUpgradeLoading(true)
      const req = await fetch(UPGRADE_PLAN_URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
        },
        method: 'POST',
      })
      if (!req.ok) {
        throw new Error('Request failed')
      }
      const res = await req.json()
      if (res.subscription) {
        setUpgradeSuccess(true)
        subscriptionHandler(res.subscription)
        logEvent('ACCOUNT_UPGRADE_ANNUAL')
      } else {
        throw new Error(res.error)
      }
    } catch (e) {
      setUpgradeFail(true)
      Sentry.captureException(e, { tags: { position: 'annualPlanHandler' } })
    } finally {
      setUpgradeLoading(false)
    }
  }, [auth.currentUser, subscriptionHandler])

  return (
    <div
      data-theme='dark'
      className='fill'
      style={{
        background: 'rgba(0,0,0,0.8)',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        top: 0,
      }}
    >
      <div
        style={{
          marginTop: 150,
          alignSelf: 'center',
          background: '#47474a',
          padding: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 400,
        }}
      >
        {upgradeSuccess ? (
          <>
            <p
              style={{
                color: '#fff',
                fontSize: 36,
                fontWeight: 600,
                marginBottom: 0,
              }}
            >
              Success
            </p>
            <p style={{ color: '#d7d7d7', fontSize: 16, marginBottom: 16 }}>
              Thank you for subscribing to Motion's annual plan! You can view
              your invoice from your Account page.
            </p>
            <Button onClick={() => visibleHandler(false)}>Close</Button>
          </>
        ) : upgradeFail ? (
          <>
            <p
              style={{
                color: '#fff',
                fontSize: 36,
                fontWeight: 600,
                marginBottom: 0,
              }}
            >
              Error
            </p>
            <p style={{ color: '#d7d7d7', fontSize: 16, marginBottom: 16 }}>
              Sorry, something went wrong. Please contact support.
            </p>
            <Button onClick={() => visibleHandler(false)}>Close</Button>
          </>
        ) : (
          <>
            <p
              style={{
                color: '#fff',
                fontSize: 36,
                fontWeight: 600,
                marginBottom: 0,
              }}
            >
              Save {INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT}%
            </p>
            <p style={{ color: '#d7d7d7', fontSize: 16, marginBottom: 0 }}>
              You are currently on the ${INDIVIDUAL_MONTHLY_PRICE} monthly plan.
              Motion's annual plan is ${INDIVIDUAL_ANNUAL_PRICE}. When you
              switch, we'll pro-rate your new plan for this month.
            </p>
            {upgradeLoading ? (
              <LoadingSpinner size={24} color='white' className='mt-4' />
            ) : (
              <div className='mt-2 flex flex-col gap-3 items-center'>
                <Button sentiment='neutral' onClick={getPortalLink}>
                  {portalLoading ? 'Loading...' : 'Change payment method'}
                </Button>
                <Button sentiment='primary' onClick={annualPlanHandler}>
                  Switch to annual plan
                </Button>
                <Button
                  sentiment='neutral'
                  variant='muted'
                  onClick={() => visibleHandler(false)}
                >
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const SubscriptionPage = ({
  auth,
  subscription,
  subscriptionHandler,
  customer,
}: {
  auth: Auth
  subscription: SubscriptionData | null
  subscriptionHandler: (sub: any) => void
  customer: any
}) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [upgradeVisible, setUpgradeVisible] = useState(false)

  const getPortalLink = useCallback(async () => {
    setLoading(true)
    return fetch(STRIPE_PORTAL_URL, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
      body: JSON.stringify({ return_url: 'https://app.usemotion.com/account' }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          setError(result.error)
        }
        /* eslint promise/always-return: ["warn"] */
        if (result.session) {
          window.location.href = result.session.url
        }
      })
      .catch((e) => {
        errorInDev(e)
        setError('Something went wrong, please contact support')
      })
      .finally(() => setLoading(false))
  }, [auth.currentUser])

  const [onboardingSettings] = usePersistedOnboardingState()
  const inPrePurchaseOnboarding = newOnboardingPrePurchasePages.includes(
    onboardingSettings?.onboardingLatestScreen || ''
  )

  return (
    <div className='fill settings-main'>
      <div className='settings-card'>
        <div>
          <h3 className='account-menu-title'>Billing</h3>
          <hr className='title-divider' />
        </div>
        <div className='flex flex-col gap-2'>
          {error ? (
            <Banner sentiment='error' variant='onPage'>
              Something went wrong, please contact support
            </Banner>
          ) : null}
          {subscription?.subscription?.plan && (
            <>
              <p style={{ margin: 0 }}>
                You are on a {subscription?.subscription?.plan?.amount / 100}{' '}
                {subscription?.subscription?.plan.currency.toUpperCase()}{' '}
                {subscription?.subscription?.plan.interval_count === 3
                  ? 'quarter'
                  : subscription?.subscription?.plan.interval}
                ly plan.
              </p>
              {customer && customer.balance < 0 ? (
                <p style={{ margin: '10px 0' }}>
                  Account credits: {customer.currency.toUpperCase()}{' '}
                  {(-customer.balance / 100).toFixed(2)}
                </p>
              ) : null}
              {subscription?.subscription?.plan?.interval !== 'year' &&
                subscription?.type !== 'team_plan' &&
                subscription?.type !== 'team_plan_trial' && (
                  <Button
                    sentiment='primary'
                    // @ts-expect-error - invalid styling
                    style={{ alignSelf: 'flex-start', margin: '10px 0' }}
                    onClick={() => setUpgradeVisible(true)}
                  >
                    Switch to annual plan and save{' '}
                    {INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT}%
                  </Button>
                )}
              <Button
                sentiment='neutral'
                variant='muted'
                // @ts-expect-error - invalid styling
                style={{ padding: 0, alignSelf: 'flex-start' }}
                onClick={getPortalLink}
              >
                {loading
                  ? 'Loading...'
                  : 'Individual subscribers: Change payment method, cancel subscription, view invoices'}
              </Button>
              <p>
                If you are on a team plan, manage your plan{' '}
                <a className='text-blue-400' href='/web/team'>
                  here
                </a>{' '}
                or contact support
              </p>
            </>
          )}
        </div>
        {!subscription?.subscription?.plan && (
          <EnsureFirestoreSettingsFetched>
            {inPrePurchaseOnboarding ? (
              <a className='text-blue-400' href='/web'>
                Click here to return to onboarding
              </a>
            ) : (
              <a className='text-blue-400' href='/addPayment'>
                Subscribe to new individual plan
              </a>
            )}
          </EnsureFirestoreSettingsFetched>
        )}
      </div>
      {upgradeVisible ? (
        <UpgradePanel
          subscription={subscription}
          subscriptionHandler={subscriptionHandler}
          visibleHandler={setUpgradeVisible}
          getPortalLink={getPortalLink}
          portalLoading={loading}
        />
      ) : null}
    </div>
  )
}

const SupportPage = () => {
  return (
    <div className='fill settings-main'>
      <div className='settings-card'>
        <div>
          <h3 className='account-menu-title'>Support</h3>
          <hr className='title-divider' />
        </div>
        <p className='mb-3'>
          You can contact support by visiting{' '}
          <a className='text-blue-400' href='https://www.usemotion.com/help'>
            https://www.usemotion.com/help
          </a>
          . First check if the answer to your question is already covered in the
          FAQ The Motion team will get back to you as soon as possible. For paid
          subscribers, a member of our support team will respond within 48 hours
        </p>
        <Button
          // @ts-expect-error - old styling
          className='account-button'
          onClick={() => {
            logEvent('EXTERNAL_ACCOUNT_SUPPORT_CLICK')
            window.open('https://www.usemotion.com/help', '_blank', 'noopener')
          }}
        >
          Contact support
        </Button>
      </div>
    </div>
  )
}
